import React from "react";
import Helmet from "react-helmet";
import MainLayout from "../../../components/mainlayout";
import { navigate } from "gatsby";
import { Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import NewOrder from "../../../components/order/new";
import "bootstrap/scss/bootstrap.scss";

export default function NewOrderPage() {
  return (
    <MainLayout>
      <Helmet title="Илгээмж бүртгэх | SB Express" defer={false} />
      <Row className="mb-3">
        <Col span={24}>
          <div>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate("/");
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">Илгээмж шинээр бүртгэх</div>
        </Col>
      </Row>
      <NewOrder />
    </MainLayout>
  );
}
